import { ActionContext } from 'vuex'
import { MenusMode } from './menu'
import { nPageViewQuery } from '@/api'
import Config from '@/config'
import { createGcOptions } from 'static/lib/hooks'

export interface ViewMode {
  id: string
  useful?: boolean
  options: any
}

interface ViewsMode {
  [key: string | number]: ViewMode
}

type ViewMenus = {
  [key: string]: MenusMode
}

const view = {
  namespaced: true,
  state: () => ({
    drag: false,
    views: {} as ViewsMode,
    menus: <ViewMenus>{}
  }),

  getters: {
    drag: (state: any): any => state.drag,
    views: (state: any): any => state.views,
    viewMenus: (state: any): any => state.menus
  },

  mutations: {
    SET_DRAG (state: any, flag: boolean) {
      state.drag = flag === true
    },

    SET_VIEW (state: any, data: ViewMode) {
      const { id } = data || {}
      if (!id) return
      state.views[id] = data
    },

    SET_VIEW_MENUS (state: any, { key, menus }: ViewMenus) {
      state.menus[String(key)] = menus
    }
  },

  actions: {
    async initCacheViews ({ commit }: any, menuSids: number[] = []) {
      try {
        if (!menuSids.length) return
        const { data }: any = await nPageViewQuery({ menuIds: menuSids, projectId: Config.project.id })
        const pageViews = data.list || []
        pageViews.map((item: any) => {
          const { options } = createGcOptions(item, {})
          commit('SET_VIEW', { id: item.menuId, useful: true, options })
        })
      } catch (error) {
        console.log(error)
      }
    },

    /**
     * 设置是否可以拖拉拽
     * @param param0
     * @param mode
     */
    setViewDrag ({ commit }: any, drag: boolean) {
      commit('SET_DRAG', drag)
    },

    /**
     * 设置单个view
     * @param param0
     * @param data
     */
    setViewCache ({ commit }: any, data: ViewMode) {
      commit('SET_VIEW', data)
    },

    /**
     * 设置页面的菜单数据, key 可以是项目id、页面sid、模块sid
     */
    setViewMenus ({ commit }: ActionContext<any, any>, { key, menus }: ViewMenus) {
      commit('SET_VIEW_MENUS', { key, menus })
    }
  }
}
export default view
