import {
  createRouter,
  createWebHashHistory,
  RouteRecordRaw
} from 'vue-router'
import { constantRoutes } from './modules/constant'
import Layout from '@/components/Layout/router.vue'

const routes: Array<RouteRecordRaw> = [
  ...constantRoutes,
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '',
    component: Layout,
    children: []
  }
]
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
