/**
 * 系统
 */
export const appKeys = {
  // load micro app
  LOAD_MICRO_APP: Symbol('LOAD_MICRO_APP')
}

/**
 * 字段级别
 */
export const fieldGlobal = {
  SINGLE_FIELD: 'SINGLE_FIELD',
  FIELD_ACTIVE: Symbol('FIELD_ACTIVE')
}

/**
 * 政策
 */
export const policyPage = {
  QUERY: Symbol('PP_QUERY')
}

/**
 * 个人中心
 */
export const privatePage = {
  SET_PAGETYPE: Symbol('PRIVATE_PAGETYPE'),
  ASYNC_BASIC_BASE: Symbol('PRIVATE_ASYNC_BASIC_BASE'),
  ASYNC_BASIC_OTHER: Symbol('PRIVATE_ASYNC_BASIC_OTHER')
}

/**
 * 登录弹出
 */
export const loginDialog = {
  OPEN: Symbol('LD_OPEN')
}
