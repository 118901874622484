<template>
  <section class="layout-side" :class="[sideTheme]">
    <section class="content scroll-y">
      <AppMenu :menus="menus" :props="{ router: true }" />
    </section>
  </section>
</template>
<script lang="ts" setup>
import AppMenu from './common/menu.vue'
import useApp from '@/hooks/state/use-app'

// hooks
const { sideMenus: menus, sideTheme } = useApp()

</script>
<style lang="scss" scoped>
.layout-side {
  width: 100%;
  height: 100%;
  background: $primary-bg-aside;
  &.theme {
    background: $primary-color;
    :deep {
      .submenu-title {
        i {
          color: $white;
        }
      }
      .el-menu {
        border: none;
        background: $primary-color;
        .el-sub-menu__title:hover,
        .el-menu-item:hover,
        .el-menu-item.is-active {
          background: linear-gradient(to right, rgba($color: $white, $alpha: 0.25), rgba($color: $white, $alpha: 0)) !important;
          color: $white;
        }
        .el-menu-item {
          border-left: 3px solid transparent;
          &.is-active {
            border-color: $white;
            color: $white;
          }
        }
      }
      .el-menu-item,
      .el-sub-menu__title {
        color: $white;
      }
    }
  }

  .content {
    width: 100%;
    height: 100%;
  }
}
</style>
