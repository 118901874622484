<template>
  <div class="notice">
    <img
      src="/static/images/home/item-icon-4.png"
      @click="openDetail"
    >
    <div class="text">
      余杭区关于向社会征集产业发展专项资金项目评审专家的通知
      <!-- 医保报销、异地就医... -->
    </div>
  </div>
</template>
<script setup lang="ts">
const apiPrefix = import.meta.env.VITE_ENV === 'prod' ? '/yuhangplatform' : ''

function openDetail () {
  // window.open(apiPrefix + '/yusx/#/notice/list?keyword=热门问题')
  window.open('https://mp.weixin.qq.com/s/AeLE2ya7MrpvQYYAn5p3CA')
  
}
</script>

<style scoped lang="scss">
@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(6deg);
  }
  20% {
    transform: rotate(-6deg);
  }
  30% {
    transform: rotate(6deg);
  }
  40% {
    transform: rotate(-6deg);
  }
  50% {
    transform: rotate(6deg);
  }
  60%,
  100% {
    transform: rotate(0deg);
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.notice {
  position: fixed;
  right: 12px;
  bottom: 16%;
  z-index: 999;
  animation: scale 2s infinite;

  img {
    cursor: pointer;
    width: 159px;
    height: 101px;
  }
  .text{
    position: absolute;
    top: 55%;
    left: 50%;
    max-width: 120px;
    transform: translateX(-50%);
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 21px;
    padding:2px 4px;
    background: rgba(255,255,255,0.52);
    border-radius: 4px;
    color:#507BB8;
    border: 1px solid #FFFFFF;
  }
}
</style>
